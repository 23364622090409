@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tw-color-smet-blue: theme('colors.smet.blue.DEFAULT');
  --tw-color-smet-blue-light: theme('colors.smet.blue.light');
  --tw-color-smet-purple: theme('colors.smet.purple.DEFAULT');
  --tw-color-smet-red: theme('colors.smet.red.DEFAULT');
  --tw-color-smet-cyan: theme('colors.smet.cyan.DEFAULT');
  --tw-color-smet-gray-2: theme('colors.smet.gray.2');
}

body {
  font-family: 'Overpass', sans-serif !important;
}


/** Layout **/

main {
  @apply lg:!pl-28;
}

@media (min-width: 1024px) {
  main {
    @apply bg-fixed bg-cover !important;
    background: linear-gradient(180deg, rgba(0, 84, 232, 0.05) 42.13%, var(--tw-color-smet-blue) 156.44%);
  }
}

/** Nav **/

div:has(> .nav-sidebar) {
  @apply lg:!w-28;
}

.data-\[slot\=icon\]\:\*\:fill-zinc-500 > *[data-slot='icon'] {
  @apply !fill-smet-dark-2;
}

.data-\[slot\=icon\]\:\*\:data-\[current\]\:fill-zinc-950[data-current] > *[data-slot='icon'] {
  @apply !fill-smet-blue;
}

.data-\[slot\=icon\]\:\*\:data-\[current\]\:fill-zinc-950[data-current] > .nav-sidebar-label {
  @apply !text-smet-blue;
}

.data-\[slot\=icon\]\:\*\:data-\[hover\]\:fill-zinc-950[data-hover] > [data-slot='icon'] {
  @apply !fill-smet-blue;
}

.data-\[slot\=icon\]\:\*\:data-\[hover\]\:fill-zinc-950[data-hover] > .nav-sidebar-label {
  @apply !text-smet-blue;
}

.nav-sidebar-item > span:first-of-type {
  @apply bg-smet-blue;
}

@media (min-width: 1024px) {
  .nav-sidebar-item a,
  .nav-sidebar-item button {
    @apply flex-col gap-2;
  }
}

/** Inputs **/

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="radio"] {
  accent-color: var(--tw-color-smet-blue) !important;
}

/** Buttons **/

button {
  @apply cursor-pointer border-none !important;
  --btn-icon: unset !important;
  --btn-border: unset !important;
}

.smet-primary-button {
  --btn-bg: var(--tw-color-smet-blue) !important;
}

.smet-secondary-button {
  --btn-bg: var(--tw-color-smet-blue-light) !important;
}

.smet-ai-button {
  --btn-bg: var(--tw-color-smet-purple) !important;
}

.smet-calculator-button {
  --btn-bg: var(--tw-color-smet-cyan) !important;
}

.smet-destructive-button {
  --btn-bg: var(--tw-color-smet-red) !important;
}

.smet-inverted-button {
  @apply text-smet-blue;
  --btn-bg: transparent !important;
}

.smet-inverted-button::before {
  box-shadow: none !important;
}

.smet-disabled-button {
  @apply !cursor-not-allowed text-smet-dark-5;
}

/** Loader **/

.clip-loader {
  @apply border-t-smet-blue border-r-smet-blue border-b-transparent border-l-smet-blue !important;
}

.smet-primary-button .clip-loader, .smet-destructive-button .clip-loader {
  @apply border-t-white border-r-white border-b-transparent border-l-white !important;
}

button .clip-loader {
  @apply border-t-zinc-950 border-r-zinc-950 border-b-transparent border-l-zinc-950 !important;
}

/** Fonts **/

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Black.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Bold.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-ExtraBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-ExtraLight.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Light.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Italic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-ExtraLightItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Regular.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Medium.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-SemiBoldItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass';
  src:
    url('/assets/fonts/overpass/Overpass-Thin.woff2') format('woff2'),
    url('/assets/fonts/overpass/Overpass-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass Mono';
  src:
    url('/assets/fonts/overpass-mono/OverpassMono-Bold.woff2') format('woff2'),
    url('/assets/fonts/overpass-mono/OverpassMono-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass Mono';
  src:
    url('/assets/fonts/overpass-mono/OverpassMono-Light.woff2') format('woff2'),
    url('/assets/fonts/overpass-mono/OverpassMono-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass Mono';
  src:
    url('/assets/fonts/overpass-mono/OverpassMono-Medium.woff2') format('woff2'),
    url('/assets/fonts/overpass-mono/OverpassMono-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass Mono';
  src:
    url('/assets/fonts/overpass-mono/OverpassMono-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/overpass-mono/OverpassMono-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Overpass Mono';
  src:
    url('/assets/fonts/overpass-mono/OverpassMono-Regular.woff2') format('woff2'),
    url('/assets/fonts/overpass-mono/OverpassMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
